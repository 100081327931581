import { motion } from 'framer-motion'
import { Col, Row } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import ReactSVG from 'react-svg'
import React from 'reactn'
import Images from '../../utils/images'
import { H1, Link, Text, View } from '../../utils/styles'

function App () {
  return (
    <View bg='light' pt={100} sm={50} overflow>
      <Row id='app-section' style={{ overflow: !m ? 'hidden' : 'visible' }}>
        <Col lg={6} sm={12} md={12} xs={12}>
          <motion.div>
            <H1 size={2.5} bold={400} pt={0} pb={20} pl={m ? 50 : 100}>How  it works</H1>
          </motion.div>
          <View flex>
            {!m && <ReactSVG src={Images.design} svgStyle={{ position: 'absolute', top: 0, bottom: 0, left: 800, height: '1800px', zIndex: -30 }} />}
            <View pl={100} pr={100} pt={50} mb={150} sm={80} smpt={10} center>

              <View mb={30} pb={20} className='line' flex top>

                <View width={150}>
                  <ReactSVG src={Images.book} svgStyle={{ height: '120px', width: '120px', marginRight: 30 }} />
                </View>
                <H1 className='left' lineHeight size={1.5} bold={300} color='text'>
              Order a Pedy
                  <br />
                  <Text color='cloud' bold={300} size={0.8} >
                    You can order from your home, at work or even a hotel
                  </Text>
                </H1>
              </View>

              <View mb={30} pb={20} className='line' flex>
                <View width={150}>
                  <ReactSVG src={Images.date} svgStyle={{ height: '120px', width: '120px', marginRight: 30 }} /></View>
                <H1 className='left' lineHeight size={1.5} bold={300} color='text'>
              Order anytime before 8pm <br />
                  <Text color='cloud' bold={300} size={0.8}>Order a Pedy anytime before 8pm</Text></H1>
              </View>

              <View flex>
                <View width={150}>
                  <ReactSVG src={Images.therapist} svgStyle={{ height: '120px', width: '120px', marginRight: 30 }} />
                </View>
                <H1 className='left' lineHeight size={1.5} bold={300} color='text'>
              Wellness Practitioner Arrives <br />
                  <Text color='cloud' bold={300} size={0.8}>A Vetted Wellness Practitioner arrives at your door</Text></H1>
              </View>

            </View>
          </View>
        </Col>

        <Col lg={6} sm={12} md={12} xs={12} className='rm-p overflowV'>
          <View flex center mt={50}>
            <img src={Images.screens} alt='screens' style={{ height: '100%', width: m ? '70%' : '40%', marginBottom: 20 }} />
          </View>
        </Col>

      </Row>

      <View bg='green' pt={150} pb={150}>
        <View center>
          <H1 className='center' textCenter size={2.5} bold={500} color='white' pb={10}>
             Are you a licensed Wellness Practitioner? <br /></H1>
          <H1 className='center' textCenter size={2} bold={300} color='white' pb={40} mt={5}>
             Signup as a Partner<br /></H1>
          <View center>
            <Link href='/become-a-pedy-partner' bg='dark' color='white' p={20} pr={50} pl={50} size={1.5} bold={400} textCenter block center buttonRadius>Learn More</Link>
          </View>
        </View>
      </View>

      {/* <View bg='brand' pt={80} pb={50}>
        <View center flex>
          <View m={10} center>
            <H1 className='center' textCenter size={2.5} bold={500} color='' pb={10}>
             324 <br /></H1>
            <Text textCenter size={1} bold={300} color='' pb={40} mt={5}>
             Registered Practictioners</Text>
          </View>
          <View m={10} center>
            <H1 className='center' textCenter size={2.5} bold={500} color='' pb={10}>
             435 <br /></H1>
            <Text textCenter size={1} bold={300} color='' pb={40} mt={5}>
             Massages booked</Text>
          </View>
        </View>
      </View> */}

    </View>
  )
}

export default App
