import React from 'react'
import { Container } from 'react-bootstrap'
import { H1, View, Text } from '../../utils/styles'

function PrivacyContent () {
  return (
    <Container>
      <View mt={100} mb={100}>
        <H1 mb={50}>Service Etiquette</H1>
        <p>Pedy connects you with a professional mobile practitioners that you can trust. <Text bold={500} color='red'>All wellness services found on Pedy is entirely therapeutic and non-sexual.</Text></p>
        <p><strong>Before your treatment is due to start</strong></p>
        <p>We recommend that you have at least two clean towels to hand. These will be laid on the massage table and over your body.</p>
        <p><strong>On Arrival</strong></p>
        <p>Your therapist will arrive on time, so please make sure you're at your home, hotel room or office for the time you booked. In the unlikely event that your therapist is running late, you will be notified by the customer service team or the therapist directly.</p>
        <p>Please show your therapist where you'd like your treatment to take place and allow them to step outside so you can get undressed in privacy. Your therapist will re-enter the room once you are ready.</p>
        <p>If oils are used in your treatment, you can undress to a level you are comfortable with but you are required to wear your underwear at all times during your treatment. requests to be inappropriately uncovered will not be tolerated by your therapist.</p>
        <p><strong>During the Session</strong></p>
        <p>Your therapist will ask you a few simple questions and to complete a consultation form so they can tailor your treatment to your needs.</p>
        <p>Please make your therapist aware of any injuries and allergies you have and areas of your body you want to focus on. Feel free to communicate with your therapist about the pressure and if you do happen to feel any pain, please tell your therapist immediately. Please note that for&nbsp;deep tissue&nbsp;and&nbsp;sports massages&nbsp;you can incur moderate pain which will subside after 24-48 hours.</p>
        <p><strong>After the Session</strong></p>
        <p>Your therapist will leave the room to allow you 5 minutes to slowly get up and dressed. After, they will re-enter to obtain your feedback which will be noted against your account.</p>
        <p>It is advised that you have a treatment every 2 to 4 weeks to maintain a balanced mind and body. You can simply re-book via the Pedy website, app or customer service team.</p>
        <p>If you aren't completely satisfied with your treatment or would like to speak to our customer service team, please email&nbsp;info@Pedy.co.za</p>
        <p><strong>Cancellation</strong></p>
        <p>In the unlikely event your plans change and you wish to cancel your booking, the appointment cancellation policy is as follows:</p>
        <table width='753'>
          <thead>
            <tr>
              <td>
                <p>Notice period</p>
              </td>
              <td>
                <p>Refund amount</p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width='375'>
                <p>24 hours or more</p>
              </td>
              <td width='374'>
                <p>100%</p>
              </td>
            </tr>
            <tr>
              <td width='375'>
                <p>up to 2 hours before treatment</p>
              </td>
              <td width='374'>
                <p>70%</p>
              </td>
            </tr>
            <tr>
              <td width='375'>
                <p>within 2 hours</p>
              </td>
              <td width='374'>
                <p>0%</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p>The appointment cancellation policy is designed to protect the relationship between you and your therapist - we understand that plans can change quickly.</p>
        <p>Should you make a mistake in your booking, you have a 10 minute grace period after submitting your booking where you can cancel without any charge. to qualify, you must call the customer service team on&nbsp;0761916825 to cancel.</p>
        <p><strong>Booking Amendments</strong></p>
        <p>Booking amendments such as time and location will be subject to the above cancellation policy. If you wish to give your therapist additional instructions these are free of charge.</p>
        <p><strong>Prices and Opening hours</strong></p>
        <p>All prices quoted on the booking page are full and final, inclusive of transportation with no hidden fees.</p>
        <p>You can book treatments for between 8am to 8:00 pm, 7 days a week.</p>
        <p>For more information, please see our FAQs</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </View>
    </Container>
  )
}
export default PrivacyContent
