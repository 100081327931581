import React from 'react'
import PrivacyContent from './sections/privacycontent'
import Templates from './templates'

function Main () {
  return (
    <Templates>
      <PrivacyContent />
    </Templates>
  )
}
export default Main
