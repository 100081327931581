import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Images from '../utils/images'
import { H4, Text, Link, View } from '../utils/styles'
import Templates from './templates'
// import ReactSVG from 'react-svg'
// import { isMobile as m } from 'react-device-detect'

function Main () {
  return (
    <Templates>
      <View pt={100} mb={0} sm={10} bg='white'>
        <Container>
          <Row>
            <Col lg={6} sm={12} md={6} xs={12}>
              <View center mt={80}>
                <H4 size={3} mb={50} sm={10} color='branding' bold={300}> Introducing<br /> <Text size={1.5} bold={500}>The Pedy Band</Text></H4>
              </View>
            </Col>

            <Col lg={6} sm={12} md={6} xs={12}>
              <img src={Images.bandv} alt='band v' style={{ width: '70%' }} />
            </Col>
          </Row>
        </Container>
      </View>

      <View pt={100} mb={10} bg='white'>
        <Container>
          <Row>
            <Col lg={6} sm={12} md={6} xs={12}>
              <View flex center mt={50} mb={50}>
                <img src={Images.bandh} alt='band' style={{ width: '60%' }} />
              </View>
            </Col>

            <Col lg={6} sm={12} md={6} xs={12}>
              <H4 size={2} bold={500} color='branding' mb={20}>About the Pedy Band</H4>
              <View>
                <H4 size={1} mb={20} lineHeight bold={300}>As part of our efforts towards ensuring the security of our practitioners while they go about providing massage therapy to their customers, We're actively developing The Pedy Band which will allow us to respond to security treats that maybe directed towards our mostly, female therapists.
                </H4>

                <Text size={1.1} bold={500} center >Some features to note:</Text>
                <View mt={20}>
                  <ol>
                    <li><Text size={1}>Sensors that allows us distinguish struggle and regular hand movements.</Text></li>
                    <li><Text size={1}>SOS feature activated with a double tap.</Text> </li>
                  </ol>
                </View>
              </View>
            </Col>

          </Row>
          <H4 size={0.6} mt={100} textCenter bold={300} color='branding'>Important: The Pedy band is actively in development and not yet available for our partner therapists. We do have in place, mechanisms that ensures the protection of our partner therapists while they deliver their services on our Pedy Partner App </H4>
        </Container>
      </View>

      <View pt={100} bg='branding'>

        <View center pb={100} pr={20} pl={20}>
          <H4 size={2} bold={300} color='white' textCenter mb={20}>Keen to contribute to the Pedy Band Project?</H4>
          <H4 size={1.8} mb={50} color='white' textCenter bold={200}>Let's hear from you</H4>
          <Link href='/feedback' bg='white' color='dark' p={20} pr={50} pl={50} size={1.5} bold={400} textCenter block center buttonRadius>Send us a message</Link>
        </View>
      </View>

    </Templates>
  )
}
export default Main
