import React, { useState, useEffect, useRef } from 'react'
import Colors from '../../website/utils/colors'
import { Modal, Container } from 'react-bootstrap'
import { View, Text } from '../../website/utils/styles'
import { ReCaptcha } from 'react-recaptcha-google'
import fetch from 'node-fetch'
// 6LfXQ78ZAAAAALS7MeyEKsBcT4lQfWe057nUR9KX
// const encode = (data) => {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

function ContactForm (props) {
  const [state, setState] = useState({ name: '', emailAddress: '', message: '' })
  const [show, setShow] = useState(false)
  const captchaDemo = useRef(null)

  const handleClose = () => {
    setShow(false)
    window.location.replace('/')
  }

  const _onMount = () => {
    if (captchaDemo) {
      console.log('started, just a second...')
      captchaDemo.current.reset()
      captchaDemo.current.execute()
    }
  }
  const verifyCallback = (e) => {
    handleSubmit()
  }

  const onLoadRecaptcha = () => {
    if (captchaDemo) {
      captchaDemo.current.reset()
      captchaDemo.current.execute()
    }
  }

  const messageFeecback = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title><Text size={0.7}>Mesasge Sent <span aria-label='confetti' role='img'>🎉</span> </Text></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Thanks for leaving us a feedback.</p>
        </Modal.Body>
      </Modal>
    )
  }

  const handleSubmit = e => {
    const { name, emailAddress, message } = state
    const options = {
      body: JSON.stringify({
        msg: `${name + '\n' + emailAddress + '\n' + message}`,
        emailTemplate: '',
        link: '',
        emailAddress: 'hello@pedy.co.za'
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }
    e.preventDefault()
    fetch('https://us-central1-luggr-app.cloudfunctions.net/EmailEndpoints/send/email', options)
      .then(() => { setShow(true) })
      .catch(error => console.warn(error))

    e.preventDefault()
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    _onMount()
  }, [])

  function _renderForm () {
    return (
      <Container>
        <View mb={200} mt={70} sm={30}>
          <Text size={2} bold={500} className='lightText'>
        Your feedback is important to us <span aria-label='smile' role='img'>😊</span>
          </Text><br />
          <View mb={20}>
            <Text size={1.1} bold={300} className='lightText' >  Let's hear your thoughts on Pedy </Text>
          </View>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type='text'
                required
                name='name'
                value={state.name}
                placeholder='Full Name'
                onChange={handleChange}
                style={inputStyles}
              />
            </div>
            <div>
              <input
                type='email'
                required
                name='emailAddress'
                value={state.emailAddress}
                placeholder='Email Address'
                onChange={handleChange}
                style={inputStyles}
              />
            </div>
            <div>
              <textarea
                name='message'
                required
                style={textareaStyles}
                onChange={handleChange}
                placeholder='Message'
              />
            </div>
            <button style={submitBtnStyles} type='submit' value='SUBMIT' >
          Submit
            </button>
          </form>
          {messageFeecback()}
        </View>
        <ReCaptcha
          ref={captchaDemo}
          size='invisible'
          render='explicit'
          sitekey='6Lf2Zb8ZAAAAAOMFWQuksBDnrCenxZOo7N3Ph2WT'
          onloadCallback={onLoadRecaptcha}
          verifyCallback={verifyCallback}
        />
      </Container>
    )
  }

  return _renderForm()
}

const submitBtnStyles = {
  background: Colors.branding,
  border: 'none',
  width: '30%',
  height: '50px',
  color: 'white'
}

const inputStyles = {
  fontSize: '0.8em',
  minHeight: '50px',
  width: '100%',
  outline: 'none',
  backgroundColor: '#ededed',
  borderBottom: 'none',
  borderColor: 'transparent',
  padding: '8px 10px',
  margin: '10px 0px',
  color: 'grey'
}

const textareaStyles = {
  fontSize: '0.8em',
  minHeight: '80px',
  width: '100%',
  backgroundColor: '#ededed',
  padding: '8px 10px',
  outline: 'none',
  marginTop: '10px',
  marginBottom: '15px',
  border: 'none',
  color: 'grey',
  resize: 'none'
}

// const messageStyles = {
//   height: '400px',
//   width: '400px',
//   margin: 'auto',
//   paddingTop: '180px',
//   color: 'grey',
//   backgroundColor: 'transparent',
//   textAlign: 'center',
//   verticalAlign: 'middle',
//   fontSize: '1.2em'
// }

// const resetStyles = {
//   cursor: 'pointer'
// }
export default ContactForm
