import React from 'react'
import Header from '../components/header'
// import Content from './sections/content'
import Footer from './sections/footer'
import { View, Text } from '../utils/styles'
import Banner from 'react-js-banner'
import CookieConsent from 'react-cookie-consent-notification'

function Main (props) {
  const checkStatus = (status) => {
    if (status) {
    }
  }

  return (
    <View m={0}>
      <Banner showBanner>
        <View>Stay informed! Visit the SA department of health's website for COVID-19 updates: <a rel='noopener noreferrer' target='_blank' href='https://www.sacoronavirus.co.za'>www.sacoronavirus.co.za</a></View>
      </Banner>
      <Header {...props} />
      {props.children}
      <Footer />
      <View w={200}>
        <CookieConsent
          background={'#fff'}
          bottomPosition
          buttonText={'I Agree'}
          buttonBackground={'#ccc'}
          buttonColor={'#ccc'}
          buttonFontSize={12}
          color={'#333'}
          consentFunction={checkStatus}
          padding={30}
        >
          <Text size={0.8}>
        We use cookies on this site to personalise content and enhance your user experience. By clicking or tapping 'I Agree', you are giving your consent for us to set cookies.
          </Text>
        </CookieConsent></View>
    </View>
  )
}
export default Main
