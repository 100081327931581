import { Col, Row } from 'react-bootstrap'
import React from 'reactn'
import { H1, Text, View } from '../../utils/styles'

function Main () {
  return (
    <View flex>
      <View pl={100} pr={100} pt={50} mb={50} sm={80} smpt={10} center>
        <H1 size={2.5} bold={400} color='dark' pt={50} pb={0} textCenter center>Grow your business with Pedy</H1>

        <Row >
          <Col lg={4} sm={4} md={4} xs={12}>
            <View bg='light' className='fshadow' mt={30} center id='card' overflow minHeight={550}>
              <img src={require('../../assets/tools.jpg')} width={'100%'} alt='tools' />
              <View p={30}>
                <Text color='cloud' bold={300} size={1.1} lineHeight={13}>
                  Some of Pedy's tools include instant bookings which is a premium feature to help you earn even more, virtual sponsored events to showcase your passion and grow your customer base and cash advance where you can get a percentage of your booking fees in a week paid out to you immediately. We handle the marketing, sales and payment platforms so that you can focus on providing great service.
                </Text>
              </View>
            </View>
          </Col>

          <Col lg={4} sm={4} md={4} xs={12}>
            <View bg='light' className='fshadow' mt={30}center id='card' overflow minHeight={550} >
              <img src={require('../../assets/paid.jpg')} width={'100%'}  />
              <View p={30}>
                <Text color='cloud' bold={300} size={1.1} lineHeight={13}>
            Earn what you’re worth. Wellness professionals in the Pedy Partner network get the best pay in the industry. It is a great way to earn extra money! We respect your time. Customers pay a fee for late cancellations. No-hassle payments. Money goes directly to your bank account every week and you always see what you earned on each appointment.
                </Text>
              </View>
            </View>
          </Col>

          <Col lg={4} sm={4} md={4} xs={12}>
            <View bg='light' className='fshadow' mt={30}center id='card' overflow minHeight={550}>
              <img src={require('../../assets/location.jpg')} width={'100%'}  />
              <View p={30}>
                <Text color='cloud' bold={300} size={1.1} lineHeight={13}>
             Work where you want, when you want. Set your own schedule, day or night, 7 days a week, from 8am to 8pm, choose the locations you want: spa, hotel, workplace or in-home. If you love to travel, we will soon be nationwide! Pedy also provides a shuttle-service to and from work.
                </Text>
              </View>
            </View>
          </Col>

          <Col lg={4} sm={4} md={4} xs={12}>
            <View bg='light' className='fshadow' mt={30} center id='card' overflow minHeight={550}>
              <img src={require('../../assets/safety.jpg')} width={'100%'}  />
              <View p={30}>
                <Text color='cloud' bold={300} size={1.1} lineHeight={13}>
             Your safety is our #1 priority. We carefully vet every Pedy customer using multiple ID verification partners. Help keep our customer network 5-stars — rate every client and provide feedback. Pedy’s customer service team is available when you work: 7 days a week, 365 days a year.
                </Text>
              </View>
            </View>
          </Col>
        </Row>

      </View>

    </View>
  )
}
export default Main
