import React from 'react'
import { Container } from 'react-bootstrap'
import { View, H1 } from '../../utils/styles'

function PrivacyContent () {
  return (
    <Container>
      <View mt={100} mb={100}>
        <H1 mb={50}>Privacy Policy</H1>

        <strong><span> Introduction</span></strong>

        <p><span>The purpose of this privacy policy is to explain to customers what types of information we will collect and how that information is used. In most cases, we collect this information to ensure network integrity and that we continue to provide you with the most relevant content and best possible service that suits your needs. In some cases, we are required by law to collect personal information about customers. Except where the law requires otherwise, we undertake to protect the confidentiality of such data.</span></p>
        <p><span>&nbsp;</span></p>

        <strong><span> Confidentiality</span></strong>

        <p><span>Pedy respects customer privacy and the privacy of those accessing our website. We undertake to protect the confidentiality of our customers and users including all personal information supplied in the course of contracting with us for services. We undertake not to sell your personal information to third parties for commercial or marketing purposes.</span></p>
        <p><span>&nbsp;</span></p>

        <strong><span> Collection of Personal Data</span></strong>

        <p><span>Pedy collects personal data about our users when you visit a website ran by us and through the use of cookie technology.</span></p>
        <p><span>&nbsp;</span></p>

        <strong><span> Cookies</span></strong>

        <p><span>Your Internet browser has the in-built facility for storing small files - "cookies" - that hold information which allows a website to recognize your account. Our website takes advantage of this facility to enhance your experience. You have the ability to prevent your computer from accepting cookies but, if you do, certain functionality on the website may be impaired.</span></p>
        <p><span>&nbsp;</span></p>

        <strong><span> Use of Personal Data</span></strong>

        <p><span>Pedy may on occasion use your personal information to contact you to advise you of matters relevant to service provision and in some cases, solicit your feedback. We do not pass on any personal details to 3rd parties for any promotional purposes.</span></p>
        <p><span>We may from time to time provide information of a general nature to third parties - for example, the number of individuals visiting our website or completing a registration form, but we will not use any information that could identify those individuals.</span></p>
        <p><span>Any information Pedy collects from you through correspondence with us, whether via e-mail, telephonically or by written letter, will only be used to address the matters within that correspondence. If this requires referring such correspondence within Pedy or to a third party to ensure customer service, your personal information will only be disclosed to the point necessary to address your query or concerns, and will otherwise be kept confidential.</span></p>
      </View>
    </Container>
  )
}
export default PrivacyContent
