import React from 'reactn'
import { isMobile as m } from 'react-device-detect'
import ReactSVG from 'react-svg'
import Images from '../../utils/images'
import { View, Text, H1, H4 } from '../../utils/styles'
import { Row, Col, Image } from 'react-bootstrap'

export default function Banner (props) {
  return (
    <View bg='branding'>
      <Row style={{ zIndex: 2, position: 'relative' }}>
        <Col lg={6} sm={12} md={12} xs={12}>
          <View flex>
            <View pl={100} pr={100} pt={100} mb={150} sm={80} smpt={10} center>
              <H1 size={4} bold={400} color='white' pb={10}>
                <Text color='light'>Wellness </Text> delivered to your doorstep</H1>
              <H4 size={1.3} lineHeight color='white' bold={300} >
              Download the app now
              </H4>

              <View mt={40} width={150}>
                <Row>
                  <Col lg={6} sm={6} md={6} xs={6} className={'rm-p'}>
                    <a href='/' rel='noopener noreferrer' target='_blank'>
                      <ReactSVG src={Images.apple} svgStyle={{ width: m ? 130 : 200, opacity: 0.5 }} />
                    </a>
                  </Col>
                  <Col lg={6} sm={6} md={6} xs={6}>
                    <View ml={150} sm={100}>
                      <a href='https://play.google.com/store/apps/details?id=com.pedy.sgfx&hl=en' rel='noopener noreferrer' target='_blank'>
                        <ReactSVG src={Images.google} svgStyle={{ width: m ? 140 : 220 }} /></a>
                    </View>
                  </Col>
                </Row>
              </View>
            </View>
          </View>
        </Col>

        <Col lg={6} sm={12} md={12} xs={12} className='rm-p overflow'>
          <Image className='shadow' src={Images.banner} alt='banner' height='100%' style={{ marginLeft: m ? '-250px' : '-50px' }} />
        </Col>

      </Row>
    </View>
  )
}
