import React from 'react'

import { Container } from 'react-bootstrap'
import { View } from '../../utils/styles'

function TermsContent () {
  return (
    <Container>
      <View mt={100} mb={100}>
        <h1>Terms and Conditions</h1>

        <p>Legal</p>
        <p>Last updated: <b>28 February 2020</b></p>
        <p>Your use of Our Site, Our App and the Services is subject to these terms and conditions together with any additional terms and policies referred to in them (Our Terms).</p>

        <p>Introduction</p>
        <p>Thank you for choosing Pedy, a great place to book a professional service in your home, hotel or office.</p>

        <p>These terms and conditions of service together with any terms and policies referred to in them (Our Terms) constitute a legally binding agreement between you and Sciphergfx Pty Ltd (Pedy, we, us or our) a private company registered in South Africa with company No. 2014/184085/07 </p>

        <p>USE OF OUR SITE AND OUR APP</p>
        <p>Our Terms govern your use of our website (Our Site) and also our App (Our App) and the Services. By using Our Site, Our App or the Services, you agree to comply with Our Terms.</p>

        <p>Please read Our Terms carefully and thoroughly. If you do not accept Our Terms, you must not make a booking through Pedy for any Professional Service or purchase any Products through Pedy.</p>

        <p>PERSONAL INFORMATION</p>
        <p>Personal information includes:</p>
        <ul>
          <li>certain information that we collect automatically when you visit our website;</li>
          <li>certain information collected on registration (see below);</li>
          <li>certain information collected on submission; and</li>
          <li>optional information that you provide to us voluntarily (see below);</li>
        </ul>
        <p>But excludes:</p>
        <ul>
          <li>information that has been made anonymous so that it does not identify a specific person;</li>
          <li>permanently de-identified information that does not relate or cannot be traced back to you specifically;</li>
          <li>non-personal statistical information collected and compiled by us; and</li>
          <li>information that you have provided voluntarily in an open, public environment or forum including any blog, chat room, community, classifieds, or discussion board (because the information has been disclosed in a public forum, it is no longer confidential and does not constitute personal information subject to protection under this policy).</li>
        </ul>
        <p>COMMON EXAMPLES</p>
        <p>Common examples of the types of personal information which we may collect and process include your:</p>
        <ul>
          <li>identifying information&nbsp;&ndash; such as your name, date of birth, or identification number of any kind;</li>
          <li>contact information&nbsp;&ndash; such as your phone number or email address;</li>
          <li>address information&nbsp;&ndash; such as your physical or postal address; or</li>
          <li>financial information&nbsp;&ndash; such as your bank account details.</li>
        </ul>

        <p>ELIGIBLE USE</p>
        <p>You confirm that you are of legal age to access and use Our Site, Our App and / or the Services and are of legal capacity to agree to Our Terms. You are not eligible to use the Services if you are under the age of 18. Our Terms apply to individuals only; for any corporate bookings or purchases, please contact us at corporates@pedy.co.za or on <span>076 191 6825</span>.</p>

        <p>OUR SERVICES</p>
        <p>Pedy provides a booking and purchase service (the Services). The Services allow you to book a range of Professional services (Professional Services) that are performed in each and every case by independent self-employed professionals (Independent Professionals), and to purchase related goods (Products) that are provided in each and every case by independent retailers (Retailers). In providing the Services, Pedy acts as the agent of the Independent professionals and / or Retailers. Pedy has no responsibility for any Professional Services or Products which you book or purchase through us: we are simply involved with the booking and / or purchase process, as well as providing ancillary services (including a telephone booking service) as explained below.</p>

        <p>The Services we offer allow you to search through Our Site and purchase Professional Services and / or Products from a number of Independent professionals and / or Retailers. In enabling you to purchase Professional Services and / or Products through Our Site, we are acting as the commercial agent of those Independent professionals and / or Retailers. As part of the Services, we also provide some ancillary services such as assisting with customer service issues (again in our capacity as agent for the Independent professionals and / or Retailers).</p>

        <p>However, as stated above, the contract for the purchase of the Professional Services and / or the Products is between you and the Independent professional and / or the Retailer. This means that it is the Independent professional or Retailer (not us) who is legally responsible for providing the Professional Service and / or Product to you. However, Pedy remains responsible in respect of its obligations to you in accordance with Our Terms which shall be legally binding. If you have any questions, complaints or requests for refunds, we can be contacted directly at  info@pedy.co.za or on 076 191 6825.Pedy will remain the point of contact for you for customer service questions.</p>

        <p>Pedy shall, if requested, provide intermediary services between you and an Independent professional and / or Retailer in connection with customer service or dispute resolution matters.</p>

        <p>AGREEMENTS FOR PROFESSIONAL SERVICESAND / OR PRODUCTS</p>
        <p>PROFESSIONAL SERVICES</p>
        <p>When booked through us, the Professional Service you receive will be subject to the Independent professional's terms and conditions (Independent professional's Terms). You will be provided with and asked to confirm your acceptance of the Independent professional's Terms when making a booking through us. Pedy is not a party to the Independent professional's Terms: these terms will solely be between you and the Independent professional who provides you with your Professional Service.</p>

        <p>PRODUCTS</p>
        <p>When you purchase any Product through us, you will buy directly from the relevant Retailer (not from us) and the contractual relationship in relation to the sale will be only between you and that Retailer. We will not be a party to it. The relevant Retailer will be responsible for the sale, delivery and other after-sale care and our role is limited to acting as commercial agent to conclude the sale by accepting your order and collecting, or arranging for the collection of, your payment on behalf of that Retailer.</p>

        <p>Our receipt of full payment from you will discharge your debt to the relevant Retailer in respect of that order. Though we may assist with certain practical issues on behalf of the relevant Retailer, we do not have any contractual obligations to you and you do not have any contractual rights against us regarding any Product sold through us by any Retailer.</p>

        <p>BOOKING PROFESSIONAL SERVICES</p>
        <p>You can make a booking through Our Site or through Our App by choosing an Independent professional.</p>
        <p>You may only make a booking up to four weeks in advance.</p>
        <p>Your payment details will be requested at the time of booking and payment will be collected when you make a booking.</p>
        <p>Full payment of the Treatment Fee is due at the time of booking the Professional Service with the Independent professional through us. Full details of the prices are referred to below.</p>
        <p>The Treatment Fee belongs to the Independent professional who provides the Professional Service. Pedy, as the Independent professional's agent, collects or arranges for a third party to collect on our behalf, the Treatment Fee from you. Our receipt of the full Treatment Fee will discharge your debt to the relevant Therapist in respect of that booking.</p>
        <p>We are appointed by Independent professionals to conclude bookings on their behalf as their commercial agent. Once your booking is accepted by us on behalf of the and the Independent professional, you will receive confirmation of your appointment from us by email.</p>

        <p>By making a booking, you are responsible for:</p>
        <p>Full payment of the applicable Treatment Fee;</p>
        <p>Ensuring the Independent professional has access to your Designated Premises which must, in all cases, represent a suitable space in which the Professional Service can be performed, with all appropriate facilities (including adequate lighting and heating); and</p>
        <p>Ensuring the health and safety of the Independent professional whilst at your Designated Premises.</p>
        <p>PURCHASING PRODUCTS</p>
        <p>Products may be ordered by clicking on the items you wish to purchase and then following the prompts that will appear on-screen. You may also order Products through an Independent professional, who shall initiate the order on your behalf and you will receive an email prompting you to finalise the order. You may check and correct any input errors in your order up until the point at which you submit your order. You must agree to any Retailer terms presented to you before you will be able to proceed. The order is submitted by clicking the [Buy Now] button on the checkout page.</p>

        <p>After placing an order, you will receive an acknowledgment email that your order has been received and giving you an order reference number. Please note that this does not mean that your order has been accepted. Your order constitutes an offer to the relevant Retailer to buy the Product(s) ordered. All orders are subject to acceptance by the relevant Retailer. The Retailer is not obliged to accept your order and may, at their discretion, decline to accept any order. You do, however, acknowledge that by clicking on the [Buy Now] button, you enter into an obligation to pay for the Product(s). Where your order is accepted, such acceptance will be confirmed by sending you an email confirmation that your order has been despatched or, if you opt (where available) to collect, when it will be ready for collection (Order Confirmation). You will receive an Order Confirmation in respect of each Retailer from whom you are making a purchase. The agreement between you and the relevant Retailer in relation to the Product(s) ordered (Retailer Agreement) will only be formed and become binding when the Order Confirmation is sent to you. After entering into the Retailer Agreement, the relevant Retailer will be under a legal duty to supply you with goods that are in conformity with the Retailer Agreement.</p>

        <p>The Retailer Agreement will relate only to the Product(s) which have been confirmed in the Order Confirmation. The relevant Retailer will not be obliged to supply any other Product(s) which may have been part of your order until such Product(s) have been confirmed in a separate Order Confirmation.</p>

        <p>DELIVERY OR COLLECTION OF PRODUCTS</p>
        <p>Your order will be fulfilled by the delivery date set out in the Order Confirmation or, if no delivery date is specified, then within 30 days after the date of the Order Confirmation, unless there are exceptional circumstances.</p>

        <p>Your order will be delivered to the delivery address within the Republic of South Africa you specify when placing your order, unless you opt (where available) to collect it from one of the collection points offered by the relevant Retailer. Your Order Confirmation will include details of the delivery or collection.</p>

        <p>Products comprised within the same order cannot be delivered to different addresses. Deliveries cannot be made to PO Boxes.</p>

        <p>Please also note that you must comply with all applicable laws and regulations of the country for which the Product(s) are destined. The relevant Retailer will not be liable for any breach by you of any such laws.</p>

        <p>RISK AND OWNERSHIP</p>
        <p>The Product(s) ordered will be at your risk from the time of delivery or collection (as the case may be). Ownership of the Product(s) ordered will also pass to you on delivery or collection (as the case may be), provided full payment of all sums due in respect of the Product(s), including any delivery charges, has been received.</p>

        <p>PAYMENT</p>
        <p>All Treatment Fees and / or Product Fees are payable through Our Site, Our App or over the telephone. We collect or arrange collection of payment of Treatment Fees on behalf of the Independent professional, and of Product Fees on behalf of the Retailer. In each case, our receipt of your payment as agent for the Retailer or Independent professional discharges your debt to that Retailer or Independent professional for the amount paid.</p>

        <p>We will do all that we reasonably can to ensure that all of the information you give us when paying for the Professional Service and / or Product(s) is secure by using an encrypted secure payment mechanism. However, in the absence of negligence on the part of Pedy, we will not be legally responsible to you for any loss that you may suffer if a third party gains unauthorised access to any information that you may give us at any time.</p>

        <p>All payments by credit card or debit card need to be authorised by the relevant card issuer. We may also need to use extra security steps via Verified by Visa where applicable.</p>

        <p>By making a booking or purchase you agree to provide complete, correct and true information including without limitation billing and payment information.</p>

        <p>PRICES</p>
        <p>All fees and charges are inclusive of VAT.</p>

        <p>PROFESSIONAL SERVICES</p>
        <p>The price of Professional Services (each a Treatment Price and collectively Treatment Prices) varies according to the type and duration of the Professional Service you book and also the location of the premises you have designated for the Professional Service to be provided to you by the Independent professional (Designated Premises). Treatment Prices are set from time to time and the Treatment Price you will be required to pay for a specific Professional Service (each a Treatment Fee and collectively Treatment Fees) will be determined by reference to the Treatment Prices in force at the date when that Professional Service is booked.</p>

        <p>Full details of Treatment Prices are set out in Our Site and Our App. Treatment Prices are liable to change at any time, but changes will not affect any booking which you have already made.</p>

        <p>PRODUCTS</p>
        <p>The price of Products (each a Product Price and collectively Product Prices) varies according to the Product selected. Product Prices are set from time to time and the Product Price you will be required to pay for a specific Product (each a Product Fee and collectively Product Fees) will be determined by reference to the Product Prices in force at the date when that Product was purchased.</p>

        <p>Product Prices exclude delivery costs, which will be added (at the cost shown) to the total amount due when you view the items in your shopping basket.</p>

        <p>Product Prices and delivery costs are liable to change at any time, but changes will not affect orders in respect of which you have already been sent an Order Confirmation.</p>

        <p>Our Site, Our App and our telephone booking service contain a large number of Products and it is always possible that, despite best efforts, some of the Products listed on Our Site, Our App or our telephone booking service may be incorrectly priced. Prices charged will normally be verified as part of the despatch procedures so that, where a Product's correct price is less than the stated price, you will be charged the lower amount. If a Product's correct price is higher than the price stated on Our Site, Our App or our telephone booking service, the relevant Retailer will normally either contact you for instructions before despatching the Product, or your order will be rejected in which case you will be notified of such rejection.</p>

        <p>Full details of Product Prices are set out in Our Site and Our App.</p>

        <p>YOUR RESPONSIBILITIES</p>
        <p>In addition to the responsibilities noted above, it is your responsibility to provide complete and accurate information at the time of booking or purchase. Failure to provide complete and accurate information may result in a rejection of your booking request, cancellation of your booking, an inability of the booked Independent professional to provide the Professional Service as requested or an inability of the Retailer to provide the Product. Such failure may also result in loss or incorrect delivery of your booking confirmation or Order Confirmation.</p>

        <p>CANCELLATIONS AND REFUNDS</p>
        <p>PROFESSIONAL SERVICES</p>
        <p>You acknowledge that you do not have any statutory right to cancel a booking made for a Professional Service. However, you have a contractual entitlement to cancel any booking you have made with an Independent professional through us in the following circumstances and on the described terms.</p>

        <p>Subject to the cancellation being a Late or Very Late Cancellation (as described below), if you change your mind about your booking prior to the agreed appointment start time in that booking (Appointment Time) then the Independent professional will be willing to treat your booking as cancelled (without requiring payment of all or any part of the applicable Treatment Fee and without levying any cancellation fee) if you cancel your booking via Our Site or Our App or via the telephone booking service (on 076&nbsp;191 6825&gt;): at least twenty-four (24) hours prior to the Appointment Time; or if the Appointment Time falls within twenty-four (24) hours of the time of booking, within ten (10) minutes of you having confirmed that you want to make a booking (Grace Period).</p>
        <p>If your cancellation of a booking is: within twenty-four (24) hours of the Appointment Time; or after the Grace Period has elapsed, then unless the cancellation represents a Very Late Cancellation (as described below), we will be entitled to retain (or charge, as the case may be) thirty per cent (30%) of the applicable Treatment Fee because your cancellation represents a Late Cancellation.</p>

        <p>However, if your cancellation of a booking is within two (2) hours of the Appointment Time and if applicable, the Grace Period has elapsed, the Independent professional will be entitled to retain (or charge, as the case may be) one hundred per cent (100%) of the applicable Treatment Fee because your cancellation represents a Very Late Cancellation.</p>
        <p>You will also be charged the full Treatment Fee if you: Cancel a booking other than as permitted above; Attempt to cancel a booking on or after the Appointment Time; or Fail to attend a booking at the Appointment Time and/or at the Designated Premises.</p>
        <p>A cancellation fee is charged in order to compensate the Independent professional because it is not reasonable to expect the Independent professional to be able to provide a Professional Service at another booking where you cancel with short or no notice.</p>
        <p>Cancellation fees may in our absolute discretion be waived where you have been unable to cancel a booking without incurring the cancellation fee for genuine reasons which were outside of your control. Where cancellation fees are waived by us, we act as agent of the Independent professional who is the principal in supplying the Professional Service.</p>

        <p>PRODUCTS</p>
        <p>Except in relation to certain Products set out below, you may cancel an order for a Product at any time before your order is delivered and up to 14 days afterwards, beginning on the day after your order (in its entirety) is delivered to you or, if you opt (where available) to collect it from one of the collection points offered by the courier and that collection point is managed by a third party other than the courier (for example, a local convenience store), the day after it is delivered to that third party (Cooling-off Period). If you cancel, you will receive a full refund of the price paid for the Product(s) in accordance with the refunds policy (see below).</p>

        <p>To cancel an order, you must clearly inform the relevant Retailer, preferably: in writing, by email or telephone, giving your name, address and order reference; or by completing and submitting our cancellation form available on Our Site or Our App, a copy of which is also enclosed with the Product(s) on delivery.</p>
        <p>You must also return the Product(s) within 14 days after the day you notify your cancellation, in the same condition in which you receive them (which does not interfere with your right to take any reasonable steps to examine the Product(s) and make sure they conform to your order). Products must be returned to the relevant Retailer. You have a legal obligation to take reasonable care of the Product(s) while in your possession. If you fail to comply with this obligation, the relevant Retailer may have a right to deduct the cost of any deterioration (due, for example, to your having used the Product(s)), up to the price of the Product(s), from the refund to which you are otherwise entitled.</p>

        <p>To return the Product(s), you should package the parcel securely (making sure you include a note of your name and address (enclosing any returns slip, if you have been provided with one) inside the parcel) and then return it to the relevant Retailer, either by courier or by recorded delivery mail or other form of certified mail or, if the Product(s) are too bulky to return by mail, then by a suitable carrier, to the returns address stated either in the Order Confirmation or the delivery note inside the delivered parcel.</p>
        <p>You are advised to take out enough postal/carriage insurance to cover the value of the contents. Please save your proof of posting/despatch and tracking information until your refund has been processed. You will be responsible for the cost and risk of returning the Product(s).</p>
        <p>Details of the consumer rights described above, and an explanation of how to exercise them, are provided in the Order Confirmation. Nothing in this section affects your legal rights.</p>

        <p>PRODUCT REFUND POLICY</p>
        <p>If you cancel an order within the Cooling-off Period, any refund due to you will be processed as soon as possible and, in any case, within 14 days after the day on which the relevant Retailer receives the Product(s) back or, if earlier, the day on which the relevant Retailer receives evidence that you have returned the Product(s) to the returns address (see above). You will be refunded the price paid in full (subject to any deduction the relevant Retailer is entitled to make due to your use of or damage to the Product(s)), including the cost of standard delivery. However, you will not be refunded your cost of returning the Product(s). If you received any promotional or other discount when you paid, any refund will only reflect the amount you actually paid.</p>
        <p>Refunds are made using the same method originally used by you to pay for your purchase, unless agreed otherwise.</p>

        <p>FAULTY PRODUCTS</p>
        <p>If any Product you order is damaged or faulty when delivered to you or has developed a fault, you may have one or more legal remedies available to you, depending on when you make the relevant Retailer aware of the problem, in accordance with your legal rights. If you believe a Product was delivered damaged or faulty or has developed a fault, you should inform the relevant Retailer as soon as possible, preferably in writing, giving your name, address and order reference.</p>

        <p>Please note that we have no control over any Retailer or the quality of any of the Products or service the Retailer provides, we do not give any commitment regarding them, and we are not able to provide, and have no responsibility or liability for providing, any compensation to you on behalf of any Retailer.</p>

        <p>Nothing in this section affects your legal rights.</p>

        <p>EXTENSIONS AND DELAYS</p>
        <p>If you wish to extend the time you have booked a Professional Service, the Independent professional will endeavour to fulfil your request. This is subject to the availability of the Independent professional and payment of an additional charge for the extended time calculated in accordance with the Treatment Prices (each an Extra Time Charge and collectively, Extra Time Charges).</p>

        <p>If you are delayed and unable to start the Professional Service at the Designated Premises at the Appointment Time by more than 10 minutes then, unless the Independent professional agrees to the contrary (which is within the sole discretion of the Independent professional): the Independent professional is entitled to end the Professional Service at the time agreed when the booking was made without making any adjustment to the Treatment Price to reflect the reduced time of the Professional Service; or if you and the Independent professional agree to proceed with the Professional Service for the full time allotted notwithstanding the delayed start time, then you shall be obliged to pay before the relevant Professional Service is given, an additional charge calculated at the rate set out with the Treatment Prices for extra time.</p>
        <p>We will collect the Extra Time Charges as agent of the Independent professional.</p>

        <p>PEDY ACTS ONLY AS AN INTRODUCER</p>
        <p>The Services enable those seeking Professional Services to book appointments with Independent professionals seeking to provide such Professional Services. Whilst Pedy assesses Independent professionals who wish to provide Professional Services, we do not guarantee or warrant, and make no representations regarding the reliability, quality or suitability of Independent professionals. This is entirely a matter for you. Accordingly, you acknowledge and agree that Pedy does not have any obligation to conduct any background checks on any Independent professional and have no obligation in respect of any service they may provide.</p>

        <p>When interacting with any Independent professional you should exercise caution and common sense to protect your personal safety, details and property, just as you would when interacting with other persons who are unknown to you.</p>

        <p>PROMO CODES AND REFERRALS</p>
        <p>We may from time to time create and offer promotional codes and referral codes (together Codes) that can be redeemed against purchases of Professional Services or Products. Codes will only be valid for a period of time stated on or with them.</p>

        <p>Codes do not have any cash value. Codes may:</p>

        <p>(1) only be used for personal and non-commercial purposes. You can share your unique code with your personal connections via social media where you are the primary content owner. Codes may not be duplicated, sold, transferred, distributed or made available to others online (including through public sites such as coupon sites) or by other means;</p>
        <p>(2) not be promoted in any way including via a search engine;</p>
        <p>(3) not be exchanged for cash;</p>
        <p>(4) only be used once and only one Code may be used per person; and</p>
        <p>(5) may be subject to specific terms which will be made available by us including but not limited to expiration / use by date as stated on the promotion, and must only be used in accordance with those terms.</p>

        <p>In addition, from time to time we may issue referral codes to allow you to refer friends and family to the Services, and which may reduce the Treatment Fee and / or Product Fee (Referral Codes). Referral Codes will be limited to 25 referrals per user. If you wish to continue using the Referral Code for more than 25 referrals, please email <a href='mailto:info@pedy.com'>info@pedy.com</a> and we may (but are not obliged to) extend the use of the relevant Referral Code.</p>

        <p>Discounts cannot be used in conjunction with any other offers or discounts.</p>

        <p>PROHIBITED USE</p>
        <p>The Services are for your personal and non-commercial use and must only be used for the purposes of inquiring about or making bookings for Professional Services and / or purchasing Products as expressly described above. You must not use Our Site, Our App or the Services to do any of the following (each of which is strictly prohibited):</p>

        <p>Engage in any inappropriate behaviour including but not limited to illicit or sexually suggestive remarks, sexual advances, drug use, excessive drinking and/or other inappropriate behaviour;</p>
        <p>Restrict or inhibit any other user from using and enjoying the Services;</p>
        <p>Infringe the privacy rights, property rights, or other civil rights of any person;</p>
        <p>Harass, abuse, threaten or otherwise infringe or violate the rights of Independent professionals, Pedy (including its employees and personnel) or others;</p>
        <p>Harvest, data-mine or otherwise collect information about others, including email addresses, without their consent;</p>
        <p>Use technology or other means to access our computer network, unauthorised content or non-public spaces;</p>
        <p>Introduce or attempt to introduce any viruses or any other harmful code, files or programs that interrupt or otherwise or limit the Services, Our Site or Our App's functionality, or damage, disable or otherwise impair our servers or networks or attempt to do the same; or</p>
        <p>Engage in or encourage others to engage in criminal or unlawful conduct or breach Our Terms including misuse of the Services for unlawful or unauthorised purposes.</p>
        <p>You agree not to breach Our Terms in any way which may result in, among other things, termination or suspension of your access to the Services.</p>

        <p>INTELLECTUAL PROPERTY</p>
        <p>PEDY, the Pedy logo, brand and all other intellectual property rights, trademarks, service marks, graphics and logos used in connection with the Site, App or the Services (whether registered or unregistered) belong to us or our licensors (as are applicable) and are protected by intellectual property law. Nothing in Our Terms grants you any rights in the Site, App or Services or the content within the same. All rights are reserved.</p>

        <p>DISCLAIMER</p>
        <p>As noted above, we introduce individuals seeking Professional Services to Independent professionals seeking to provide such Professional Services. We are not liable or responsible for the fulfilment of any booking or the performance of the Independent professional. You acknowledge and agree that we are not responsible for addressing any claims you have as regards any Independent professional or any Professional Services, however we do try to assist by providing the intermediary services around resolving disputes and complaints as mentioned above.</p>

        <p>OUR SERVICES ARE FOR GENERAL INFORMATION ONLY</p>
        <p>Content on Our Site and Our App which is made available as part of the Services is provided for your general information purposes only. Nothing contained on Our Site, Our App or communicated any other way as part of the Services constitutes, or is meant to constitute, advice, opinion or guidance of any kind. We are not a health care provider nor do we provide medical advice or medical treatment. References in Our Site, Our App and elsewhere to treatment and therapy or any similar terms do not refer to medical treatment or medical therapy. The information presented on Our Site, Our App and otherwise as part of the Services is not intended to diagnose health problems or to take the place of professional medical care. Should you have a medical issue you should always consult with a qualified medical professional.</p>

        <p>WE CANNOT GUARANTEE OUR SITE, OUR APP OR THE SERVICES</p>
        <p>We make no warranty or guarantee that Our Site, Our App or any other aspect of the Services is suitable for your intended use, error-free, timely, reliable, entirely secure, virus-free or available. We make no guarantee of particular results or outcomes by use of Our Site, Our App or any other aspect of the Services.</p>

        <p>Nothing in Our Terms will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit and nothing in Our Terms will exclude or limit our liability in respect of any: death or personal injury caused by the negligence of Pedy, fraud or fraudulent misrepresentation by Pedy, or any matter which it would be illegal or unlawful for Pedy to exclude or limit, or to attempt or purport to exclude or limit, its liability.</p>

        <p>We are not liable or responsible for any errors in or failure to provide the Services due to your error or failure to provide accurate and complete information.</p>

        <p>Whilst we make every effort to ensure that the Services are available, we do not represent, warrant or guarantee in any way the continued availability at all times or the uninterrupted use by you of the Services. We reserve the right to suspend or cease the operation of all or part of the Services from time to time at our sole discretion.</p>

        <p>Use of Our Site, Our App and the Services is on an as-is and as available basis. To the maximum extent permitted at law in no event shall we be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, loss of data, lost revenues, loss of goodwill, loss of anticipated saving or profits, or arising out of or in any way connected with the use or performance of the site or services, or with the delay or inability to use the site or services, or with the provision of or failure to provide the site or services.</p>

        <p>INDEMNITY</p>
        <p>You agree to defend and indemnify us from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, that arise from or relate to your use or misuse of, or access to the Services and otherwise from your violation of Our Terms.</p>

        <p>MODIFICATION AND TERMINATION</p>
        <p>We may modify Our Terms or terminate use of the Services at any time by giving notice to you. If you do not agree to any changes, you must stop using the Services. We may also change, suspend, terminate or discontinue any aspect of the Services including availability of certain features at any time for any reason.</p>

        <p>SEVERABILITY</p>
        <p>If any provision of Our Terms is deemed or becomes invalid, the validity of the other provisions shall not be affected.</p>

        <p>GOVERNING LAW AND JURISDICTION</p>
        <p>You agree that Our Terms for all purposes, shall be governed by and construed in accordance with South African &nbsp;law. You also agree to submit to the exclusive jurisdiction of the South African courts as regards any claim or matter arising under Our Terms.</p>

        <p>Please email <a href='mailto:info@pedy.com'>info@pedy.com</a> for any other enquiries.</p>
      </View>
    </Container>
  )
}
export default TermsContent
