import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import ReactSVG from 'react-svg'
import Images from '../../utils/images'
import { Text, View } from '../../utils/styles'

class Footer extends Component {
  render () {
    return (
      <View id='footer' ref={(footer) => { this.footer = footer }} pt={50} pb={50}>
        <div className='container'>

          <Row>

            <Col lg={3} sm={6} md={3} xs={6} className='br'>
              <Text size={0.9} bold={500} spacing>PEDY</Text>
              <View mt={20}>
                <ul>
                  <li><a href='/careers' bold={300}>Careers</a></li>
                  <li><a href='/service-etiquette' bold={300}>Service Etiquette</a></li>
                  <li><a href='/feedback' bold={300}>Feedback</a></li>
                </ul>
              </View>
              {!m && <Col lg={3} sm={3} md={3} xs={3} className={'rm-p'}>
                <View mb={30}><ReactSVG src={Images.logo} svgStyle={{ width: m ? '50px' : '80px' }} /></View>
              </Col>}
            </Col>

            <Col lg={3} sm={6} md={3} xs={6} className='br'>
              <Text size={0.9} bold={500} spacing>LEGAL</Text>
              <View mt={20}>
                <ul>
                  <li><a href='/terms-and-conditions' bold={300}>Terms and Conditions</a></li>
                  <li><a href='/privacy-policy' bold={300}>Privacy Policy</a></li>
                </ul>
              </View>
            </Col>

            <Col lg={3} sm={6} md={3} xs={6} className='br'>
              <Text size={0.9} bold={500} spacing>PRODUCTS</Text>
              <View mt={20}>
                <ul>
                  <li><a href='/the-pedy-band' bold={300}>Pedy Band</a></li>
                  <li><a href='/become-a-pedy-partner' bold={300}>Pedy Partner</a></li>
                </ul>
              </View>
            </Col>

            <Col lg={3} sm={6} md={3} xs={6} className={'br'}>
              <Text size={0.9} bold={500} spacing>MOBILE APPS</Text>
              <View mt={20} mb={20}>
                <a href='/' rel='noopener noreferrer' target='_blank'>
                  <ReactSVG src={Images.apple} svgStyle={{ width: m ? 70 : 100, opacity: 0.5 }} />
                </a>
                <a aria-hidden='true' href='https://play.google.com/store/apps/details?id=com.pedy.sgfx&hl=en' rel='noopener noreferrer' target='_blank'>
                  <ReactSVG src={Images.google} svgStyle={{ width: m ? 80 : 110, marginTop: 10 }} />
                </a>
              </View>
            </Col>
          </Row>

          <View center mt={40}>
            <Text center textCenter>©  2020 Pedy</Text><br />
            {/* <span style={{ color: '#ccc', fontSize: '11px' }}>  Powered By <a style={{ color: '#ccc' }} href='http://sciphergfx.com'>Sciphergfx Creative Solutions</a></span> */}
          </View>
        </div>
      </View>

    )
  }
}
export default Footer
