import Colors from '../../website/utils/colors'
import styled from 'styled-components'
import { isMobile as m } from 'react-device-detect'

const ma = (size, props, type = null) => { // mobile adjustments
  if (props.sm) {
    if (props.smpt && type === 'pt') {
      return m ? size - props.smpt : size
    } else {
      return m ? size - props.sm : size
    }
  } else {
    return size
  }
}

const styler = (type) => styled[type]`
  ${props => props.size ? 'font-size: ' + ma(props.size, props) + 'em' : null};
  ${props => props.color ? 'color: ' + Colors[props.color] + ' !important ' : 'none'};
  ${props => props.textCenter ? 'text-align: center' : null};
  ${props => props.bold ? 'font-weight: ' + props.bold : 'normal'};
  ${props => props.lineHeight ? 'line-height: 30px' : null};
  ${props => props.spacing ? 'letter-spacing: 2px' : null};


  ${props => props.buttonRadius ? 'border-radius: 40px' : null};
  ${props => props.outline ? 'border: 2px solid' + Colors[props.outline] : null};

  ${props => props.flex ? 'display: flex' : null};
  ${props => props.block ? 'display: table' : null};
  ${props => props.width ? 'width: ' + props.width + 'px' : null};
  ${props => props.height ? 'height: ' + props.height + 'px' : null};
  ${props => props.maxHeight ? 'max-height: ' + props.maxHeight + 'px' : null};
  ${props => props.minHeight ? 'min-height: ' + props.minHeight + 'px' : null};
  ${props => props.full ? 'height: 100%' : null};
  ${props => props.top ? 'alignItems: top; alignSelf: top;' : null};
  ${props => props.row ? 'flexDirection: row; justifyContent: space-between' : null};
  ${props => props.row ? 'flexDirection: row; justifyContent: space-between' : null};
  ${props => props.overflow ? 'overflow: hidden !important' : null};
  ${props => props.bg ? 'background-color: ' + Colors[props.bg] : null};
  ${props => props.center ? 'margin: auto; justify-content: center;' : null};

  ${props => props.p ? 'padding: ' + ma(props.p, props) + 'px' : null};
  ${props => props.pr ? 'padding-right: ' + ma(props.pr, props) + 'px' : null};
  ${props => props.pl ? 'padding-left: ' + ma(props.pl, props) + 'px' : null};
  ${props => props.pt ? 'padding-top: ' + ma(props.pt, props, 'pt') + 'px' : null};
  ${props => props.pb ? 'padding-bottom: ' + ma(props.pb, props) + 'px' : null};
  ${props => props.m ? 'margin: ' + ma(props.m, props) + 'px' : null};
  ${props => props.mr ? 'margin-right: ' + ma(props.mr, props) + 'px' : null};
  ${props => props.ml ? 'margin-left: ' + ma(props.ml, props) + 'px' : null};
  ${props => props.mt ? 'margin-top: ' + ma(props.mt, props) + 'px' : null};
  ${props => props.mb ? 'margin-bottom: ' + ma(props.mb, props) + 'px' : null};
`

export const H1 = styler('h1')
export const H2 = styler('h2')
export const H3 = styler('h3')
export const H4 = styler('h4')
export const Text = styler('span')
export const View = styler('div')
export const Link = styler('a')
export const TextGradient = styled.span`
  background: -webkit-linear-gradient(${Colors.pink}, ${Colors.branding});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
