import { Plus, Minus } from 'react-feather'
import { isMobile as m } from 'react-device-detect'
import React, { useState } from 'reactn'
import { H1, View, Link, Text } from '../utils/styles'
import Collapsible from 'react-collapsible'
import Templates from './templates'

function Main () {
  const [index, setIndex] = useState(null)

  const onOpen = (e) => {
    setIndex(e)
  }
  const form = () =>
    <Link href='https://docs.google.com/forms/d/e/1FAIpQLSdXfnSBdfFPX7W7oL73DjpU6YxzOP2A3LY83NnHyJjLBTtXKg/viewform' bg='dark' color='white' p={10} pr={40} pl={40} mt={30} size={1} bold={400} center block left buttonRadius>Apply</Link>
  const el = (e, i) => {
    return (
      <View>
        <Text sm={1}>{e}</Text>
        {index === i ? <Minus style={{ float: 'right' }} /> : <Plus style={{ float: 'right' }} /> }
      </View>
    )
  }
  return (
    <Templates>
      <View m={30} center mr={m ? 50 : 250} ml={m ? 50 : 250}>
        <H1 size={2.5} bold={400} color='dark' pt={50} pb={50} textCenter >Careers at Pedy (Remote)</H1>

        <View block center>
          <Collapsible tabIndex={0} trigger={el('Onboarding Partners Specialist', 0)} triggerTagName='h5' onClosing={(e) => onOpen(null)} onOpening={(e) => onOpen(0)}>
            <View mt={50}>
              <Text block >
                <b>Qualifications</b> <br /><br />

                - A minimum of two (2) years’ experience required <br />
                - Bachelor’s degree / Diploma in relevant field<br />
                - Strong written and oral communication skills <br />
                - Strong organizational skills <br />
                - Exemplary customer service skills including the ability to identify needs, provide prompt response, and exercise patience, respect and professionalism in all interactions <br />
              </Text>
              {form()}
            </View>
          </Collapsible>

          {/* <Collapsible tabIndex={1} trigger={el('Digital Activities Specialist', 1)} triggerTagName='h5' onClosing={(e) => onOpen(null)} onOpening={(e) => onOpen(1)}>
            <View mt={50}>
              <Text block >
                <b>Qualifications</b> <br />

                - A minimum of three (4) years’ experience  <br />
                - Bachelor’s degree / Diploma Required<br />
                - Strong written and oral communication skills <br />
                - Strong organizational skills <br />
                - Exemplary customer service skills including the ability to identify needs, provide prompt response, and exercise patience, respect and professionalism in all interactions <br />
              </Text>
              {form()}
            </View>
          </Collapsible>

          <Collapsible tabIndex={2} trigger={el('Distribution Channels Specialist', 2)} triggerTagName='h5' onClosing={(e) => onOpen(null)} onOpening={(e) => onOpen(2)}>
            <View mt={50}>
              <Text block >
                <b>Qualifications</b> <br />

                - A minimum of three (4) years’ experience  <br />
                - Bachelor’s degree / Diploma Required<br />
                - Strong written and oral communication skills <br />
                - Strong organizational skills <br />
                - Exemplary customer service skills including the ability to identify needs, provide prompt response, and exercise patience, respect and professionalism in all interactions <br />
              </Text>
              {form()}
            </View>
          </Collapsible> */}
        </View>

      </View>
    </Templates>
  )
}
export default Main
