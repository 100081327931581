import React from 'react'
import Templates from './templates'
import ReactSVG from 'react-svg'
import { View, Text, H4 } from '../utils/styles'
import Images from '../utils/images'
import { Row, Col, Container } from 'react-bootstrap'
// import { isMobile as m } from 'react-device-detect'

function Main () {
  return (
    <Templates>
      <View pt={100} bg='branding' pb={30}>
        <Container>
          <Row>
            <Col lg={6} sm={12} md={6} xs={12}>
              <H4 size={2.8} mb={50} color='white' bold={300} >  We're expanding quickly, Pedy is currently in these cities </H4>
              <Row>
                <Col><Text color='white' bold={300}>Centurion</Text></Col>
                <Col><Text color='white' bold={300}>Pretoria</Text></Col>
              </Row>
              <Row>
                <Col><Text color='white' bold={300}>Johannesburg</Text></Col>
                <Col><Text color='white' bold={300}>Cape town </Text></Col>
              </Row>
            </Col>

            <Col lg={6} sm={12} md={6} xs={12}>
              <ReactSVG src={Images.map} svgStyle={{ width: '100%', marginTop: -100 }} />
            </Col>
          </Row>
        </Container>
      </View>
    </Templates>
  )
}
export default Main
