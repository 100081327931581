const Images = {
  logo: require('../assets/logo.svg'),
  sa: require('../assets/sa.png'),
  bandh: require('../assets/pedy_h.png'),
  bandv: require('../assets/pedy_v.png'),
  banner: require('../assets/banner.jpg'),
  partner: require('../assets/partner.jpg'),
  partnerscreen: require('../assets/partnerscreen.png'),
  screens: require('../assets/screens.png'),
  app: require('../assets/app.svg'),
  map: require('../assets/map.svg'),
  book: require('../assets/book.svg'),
  therapist: require('../assets/therapist.svg'),
  apply: require('../assets/apply.svg'),
  earn: require('../assets/earn.svg'),
  onboarded: require('../assets/onboard.svg'),
  date: require('../assets/date.svg'),
  google: require('../assets/google.svg'),
  design: require('../assets/design.svg'),
  location: require('../assets/location.svg'),
  income: require('../assets/income.svg'),
  calendar: require('../assets/calendar.svg'),
  apple: require('../assets/apple.svg')
}

export default Images
