const Colors = {
  branding: '#5725a8',
  brandingDark: '#162f1d',
  pink: '#a52df8',
  gold: '#CEAF57',
  dark: '#212121',
  green: '#a1d897',
  red: 'red',
  ash: '#676767',
  text: '#4b4b4b',
  cloud: '#939393',
  steel: '#f3f3f3',
  white: '#ffffff'
}

export default Colors
