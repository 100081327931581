import React from 'react'
import Templates from './templates'
import EtiquetteContent from './sections/etiquettecontent'

function Main () {
  return (
    <Templates>
      <EtiquetteContent />
    </Templates>
  )
}
export default Main
