import React from 'react'
import Templates from './templates'
import TermsContent from './sections/termscontent'

function Main () {
  return (
    <Templates>
      <TermsContent />
    </Templates>
  )
}
export default Main
