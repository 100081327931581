import React from 'reactn'
import { isMobile as m } from 'react-device-detect'
import Images from '../utils/images'
import Templates from './templates'
import Benefit from './sections/benefit'
import { View, Text, H1, Link } from '../utils/styles'
import { Row, Col, Image } from 'react-bootstrap'
import ReactSVG from 'react-svg'

function Main () {
  return (
    <Templates header={<H1 size={1.6} bold={300} color='dark' pt={20}>Pedy <Text bold={500}>Partner</Text></H1>}>
      <View bg='dark'>
        <Row style={{ zIndex: 2, position: 'relative' }}>

          <Col lg={6} sm={12} md={6} xs={12}>
            <View flex>
              <View pl={100} pr={100} pt={100} mb={150} sm={80} smpt={10} center>
                <H1 size={4} sm={1} bold={400} color='white' pb={10}>Become a wellness professional on Pedy</H1>
                <Link href='https://docs.google.com/forms/d/e/1FAIpQLSflQQQIgOxpfA7SAZ9FIIh_i8i5NQBPm-e8u9Fd-2A8s6bQ2Q/viewform' bg='white' color='dark' p={20} pr={50} pl={50} size={1.5} bold={400} textCenter block left buttonRadius>Get Started</Link>
              </View>
            </View>
          </Col>

          <Col lg={6} sm={12} md={6} xs={12} className='rm-p overflow'>
            <Image className='shadow' src={Images.partner} alt='banner' height='100%' style={{ marginLeft: m ? '-250px' : '-100px' }} />
          </Col>

        </Row>
      </View>

      {/* -------------- */}
      <View>
        <View flex>
          <View pl={100} pr={100} pt={50} mb={50} sm={80} smpt={10} center>
            <H1 size={2.5} bold={400} color='dark' pt={50} pb={40}>Why Join Pedy Partner Network</H1>
            <Row >
              <Col lg={4} sm={4} md={4} xs={12}>
                <ReactSVG src={Images.apply} svgStyle={{ width: '120px', height: '120px', marginTop: 20 }} />
                <H1 className='left' size={1.5} bold={400} color='text' pt={40} mb={15}>
              Apply in minutes</H1>
                <Text color='cloud' bold={300} size={1.2} >
                  We’ll verify your identity, insurance, credentials and experience.</Text>
              </Col>

              <Col lg={4} sm={4} md={4} xs={12}>
                <ReactSVG src={Images.onboarded} svgStyle={{ width: '120px', height: '120px', marginTop: 20 }} />
                <H1 className='left' size={1.5} bold={400} color='text' pt={40} mb={15}>
              Get on-boarded</H1>
                <Text color='cloud' bold={300} size={1.2}>If your application is accepted, we’ll contact you for a short onboarding where you’ll learn how to work with us.</Text>
              </Col>

              <Col lg={4} sm={4} md={4} xs={12}>
                <ReactSVG src={Images.earn} svgStyle={{ width: '120px', height: '120px', marginTop: 20 }} />
                <H1 className='left' size={1.5} bold={400} color='text' pt={40} mb={15}>
              Start earning money</H1>
                <Text color='cloud' bold={300} size={1.2}>Download the Pedy Partner app and start taking appointments</Text>
              </Col>
            </Row>

          </View>

        </View>

        <Link href='https://docs.google.com/forms/d/e/1FAIpQLSflQQQIgOxpfA7SAZ9FIIh_i8i5NQBPm-e8u9Fd-2A8s6bQ2Q/viewform' bg='dark' color='white' p={20} pr={50} pl={50} mb={20} mt={50} size={1.5} bold={400} textCenter block center buttonRadius>Apply Here</Link>

        {/* -------------- */}
        <Benefit />

      </View>
    </Templates>
  )
}
export default Main
