import React from 'react'
import Contact from '../components/contactForm'
import Templates from './templates'

function Main () {
  return (
    <Templates>
      <Contact />
    </Templates>
  )
}
export default Main
