import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import ReactSVG from 'react-svg'
import { View } from '../utils/styles'
import Images from '../utils/images'

function Header (props) {
  return (
    <Navbar expand='lg' collapseOnSelect sticky='top' className='justify-content-between bshadow bgWhite rm-pv'>
      <Navbar.Brand href='/'>
        <View ml={100} sm={80}>
          {props.header || <ReactSVG src={Images.logo} svgStyle={{ width: m ? '70px' : '100px' }} />}
        </View>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />

      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto nav-links bold mr-nav'>
          <Nav.Link href='/become-a-pedy-partner'>Become a Pedy Partner</Nav.Link>
          <Nav.Link href={'/cities-covered'}>Cities Covered <img src={Images.sa} height={20} alt='flag' /></Nav.Link>
          <Nav.Link href={'/careers'}>Careers</Nav.Link>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  )
}
export default Header
