import React from 'react'
import Templates from './templates'
import App from '../containers/sections/app'
import Banner from './sections/banner'

function Main () {
  return (
    <Templates>
      <Banner />
      <App />
    </Templates>
  )
}
export default Main
