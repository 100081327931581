import React from 'reactn'
import { Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './website/containers/main'
import Terms from './website/containers/terms'
import Policy from './website/containers/privacy'
import Cities from './website/containers/cities'
import Partner from './website/containers/partner'
import Blank from './website/app-redirect'
import Careers from './website/containers/careers'
import Contact from './website/containers/contactus'
import Band from './website/containers/band'
import Service from './website/containers/etiquette'
import PolicyTemplate from './website/containers/sections/privacycontent'
import TermsTemplate from './website/containers/sections/termscontent'
import EtiquetteTemplate from './website/containers/sections/etiquettecontent'

export default function Routes () {
  return (
    <div>
      <Switch>
        <Route component={HomePage} exact path='/' />
        <Route component={TermsTemplate} path='/terms-and-conditions-mobile' />
        <Route component={PolicyTemplate} path='/privacy-policy-mobile' />
        <Route component={EtiquetteTemplate} path='/service-etiquette-mobile' />
        <Route component={Policy} path='/privacy-policy' />
        <Route component={Terms} path='/terms-and-conditions' />
        <Route component={Service} path='/service-etiquette' />
        <Route component={Cities} path='/cities-covered' />
        <Route component={Contact} path='/feedback' />
        <Route component={Band} path='/the-pedy-band' />
        <Route component={Blank} path='/app/:id' />
        <Route component={Careers} path='/careers' />
        <Route component={Partner} path='/become-a-pedy-partner' />
        <Redirect to='/' />
      </Switch>
    </div>
  )
}
